import React from 'react';
// eslint-disable-next-line react/no-deprecated
import { hydrate, render } from 'react-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { i18nConfig } from 'i18n/i18n.config';
import 'index.css';
import App from './app/App';

declare global {
	interface Window {
		API_URL: string;
	}
}

const localizedRoutes = Object.keys(i18nConfig.locales).map((loc) => ({
	path: `/${loc.toLowerCase()}`,
	element: <App />
}));

const router = createBrowserRouter([
	{
		path: '/',
		element: <App />
	},
	...localizedRoutes,
	{
		path: '*',
		element: <App />
	}
]);

const rootElement = document.getElementById('root');
if (rootElement && rootElement.hasChildNodes()) {
	hydrate(
		<React.StrictMode>
			<RouterProvider router={router} />
		</React.StrictMode>,
		rootElement
	);
} else {
	render(
		<React.StrictMode>
			<RouterProvider router={router} />
		</React.StrictMode>,
		rootElement
	);
}
