import { languagePickerConfig } from 'features/languagePicker/languagePicker.config';
import { i18nConfig } from 'i18n/i18n.config';
import { ReactComponent as ArrowDown } from 'images/ic-arrow-down.svg';
import classes from 'features/languagePicker/LanguagePicker.module.less';

interface IProps {
	selectedLocale: string;
	onLocaleChange: (locale: string) => void;
}

type LocaleInfo = { key: string; name: string; flagSrc: string };

const getLocaleInfoList = () => {
	const localeInfoList: LocaleInfo[] = [];
	let k: keyof typeof i18nConfig.locales;
	for (k in i18nConfig.locales) {
		const v = i18nConfig.locales[k];

		localeInfoList.push({
			key: k,
			name: v.name,
			flagSrc: languagePickerConfig.flags[k]
		});
	}

	return localeInfoList;
};

export const LanguagePicker = (props: IProps) => {
	const { selectedLocale, onLocaleChange } = props;

	const localeInfoList = getLocaleInfoList();
	const selectedLocaleInfo = localeInfoList.find((loc) => loc.key === selectedLocale);

	return selectedLocaleInfo ? (
		<div className={classes.main}>
			<div className={classes.activeLanguageBox}>
				<img
					src={selectedLocaleInfo.flagSrc}
					alt={`flag${selectedLocale}`}
					className={`${classes.flag} ${classes.flagActive}`}
				/>
				<div className={classes.dropdownButton}>
					<ArrowDown />
				</div>
			</div>
			<div className={classes.dropdownBox}>
				{localeInfoList.map((loc) => (
					<div
						className={classes.languageRow}
						key={loc.key}
						onClick={() => onLocaleChange(loc.key)}
					>
						<span className={classes.languageText}>{loc.name}</span>
						<img
							src={loc.flagSrc}
							alt={`flag${loc.key}`}
							className={`${classes.flag} ${
								loc.key === selectedLocale ? classes.flagActive : ''
							}`}
						/>
					</div>
				))}
			</div>
		</div>
	) : (
		<></>
	);
};
