const locales = {
	DE: {
		name: 'Deutsch',
		translationFileLoader: () => require('./translations/translations_de.json'),
		hrefLang: 'de'
	},
	EN: {
		name: 'English',
		translationFileLoader: () => require('./translations/translations_en.json'),
		hrefLang: 'en'
	},
	CN: {
		name: '中文',
		translationFileLoader: () => require('./translations/translations_cn.json'),
		hrefLang: 'zh'
	},
	CZ: {
		name: 'Česky',
		translationFileLoader: () => require('./translations/translations_cz.json'),
		hrefLang: 'cs'
	},
	FR: {
		name: 'Français',
		translationFileLoader: () => require('./translations/translations_fr.json'),
		hrefLang: 'fr'
	},
	GR: {
		name: 'Ελληνικά',
		translationFileLoader: () => require('./translations/translations_gr.json'),
		hrefLang: 'el'
	},
	IT: {
		name: 'Italiano',
		translationFileLoader: () => require('./translations/translations_it.json'),
		hrefLang: 'it'
	},
	PL: {
		name: 'Polski',
		translationFileLoader: () => require('./translations/translations_pl.json'),
		hrefLang: 'pl'
	},
	TR: {
		name: 'Türkçe',
		translationFileLoader: () => require('./translations/translations_tr.json'),
		hrefLang: 'tr'
	}
};
const fallback: keyof typeof locales = 'DE';

export const i18nConfig = {
	fallback,
	locales
};
