import flagCn from 'images/flags/CN.svg';
import flagCz from 'images/flags/CZ.svg';
import flagDe from 'images/flags/DE.svg';
import flagEn from 'images/flags/EN.svg';
import flagFr from 'images/flags/FR.svg';
import flagGr from 'images/flags/GR.svg';
import flagIt from 'images/flags/IT.svg';
import flagPl from 'images/flags/PL.svg';
import flagTr from 'images/flags/TR.svg';

const flags = {
	CN: flagCn,
	CZ: flagCz,
	DE: flagDe,
	EN: flagEn,
	FR: flagFr,
	GR: flagGr,
	IT: flagIt,
	PL: flagPl,
	TR: flagTr
};

export const languagePickerConfig = {
	flags
};
