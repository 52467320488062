import React, { ReactNode, useState } from 'react';
import { ReactComponent as ArrowDown } from 'images/ic-arrow-down-alt.svg';
import classes from 'components/expandBox/ExpandBox.module.less';

interface IProps {
	shownChildren: ReactNode;
	expandedChildren: ReactNode;
}

export const ExpandBox = (props: IProps) => {
	const { shownChildren, expandedChildren } = props;

	const [expanded, setExpanded] = useState(false);

	const handleExpand = () => {
		setExpanded((prevState) => !prevState);
	};

	return (
		<div className={classes.expandBox}>
			<div className={classes.expandBoxText}>
				<div className={classes.shownChildren}>{shownChildren}</div>
				<div
					className={`${classes.expandedChildren} ${
						expanded ? classes.expandedChildrenDisplayed : ''
					}`}
				>
					{expandedChildren}
				</div>
				<div className={classes.buttonBox} onClick={handleExpand}>
					<ArrowDown
						className={`${classes.expandButton} ${
							expanded ? classes.expandButtonClicked : ''
						}`}
					/>
				</div>
			</div>
		</div>
	);
};
