import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Turnstile } from '@marsidev/react-turnstile';
import { apiConfig } from 'api/api.config';
import { WebappConfig } from 'api/apiModels';
import { ExpandBox } from 'components/expandBox/ExpandBox';
import { LanguagePicker } from 'features/languagePicker/LanguagePicker';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import logo from 'images/breuninger-logo.svg';
import { ReactComponent as Info } from 'images/ic-info.svg';
import spinner from 'images/spinner.gif';
import success from 'images/success.svg';
import { CaptchaStatus } from 'model/captchaModels';
import {
	AnonymousType,
	ReportType,
	ViolationCategory,
	ViolationReportForm
} from 'model/formModels';
import { cvrUtils } from 'utils/cvrUtils';
import { formUtils } from 'utils/form.utils';
import classes from 'features/homepage/Homepage.module.less';

const allowedAttachmentTypes: string[] = [
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.oasis.opendocument.text',
	'application/pdf',
	'text/plain',
	'image/jpeg',
	'image/png',
	'image/gif'
];

interface IProps {
	locale: string;
	onLocaleChange: (locale: string) => void;
}

export const Homepage = (props: IProps) => {
	const { locale, onLocaleChange } = props;

	const [apiUrl, setApiUrl] = useState<string>(apiConfig.URL_REQUEST);
	const [attachments, setAttachments] = useState<File[]>([]);
	const [requestFailed, setRequestFailed] = useState<boolean>(false);
	const [successfullySent, setSuccessfullySent] = useState<boolean>(false);
	const [showSpinner, setShowSpinner] = useState<boolean>(false);
	const [captchaToken, setCaptchaToken] = useState<string>();
	const [captchaStatus, setCaptchaStatus] = useState<CaptchaStatus>();

	const captchaSiteKey = process.env.REACT_APP_TURNSTILE_CAPTCHA_SITE_KEY;
	const captchaLang = cvrUtils.getCaptchaLang(locale) || 'en';

	const { t } = useTranslation();
	const { width } = useWindowDimensions();

	useEffect(() => {
		fetch('./webapp-config.json')
			.then((res) => res.json())
			.then((jsonData) => {
				const data = jsonData as WebappConfig;

				setApiUrl(data.apiUrl);
			})
			.catch((err) => {
				console.log(err, ' error');
			});
	}, []);

	const {
		register,
		formState: { errors },
		watch,
		handleSubmit
	} = useForm<ViolationReportForm>({
		mode: 'onBlur',
		defaultValues: {
			reportType: ReportType.FIRST_REPORT,
			anonymousType: AnonymousType.ANONYMOUS
		}
	});

	const onAttachmentChange = (e: React.FormEvent<HTMLInputElement>) => {
		const fileListObj: FileList | null = e.currentTarget.files;
		const addedFilesArr = fileListObj !== null ? Array.from(fileListObj) : [];
		if (attachments.length + addedFilesArr.length > 3) {
			alert(t('features:home:error:max3Files'));
		} else if (addedFilesArr.length > 0) {
			const approvedFiles: File[] = [];
			addedFilesArr.forEach((file) => {
				if (allowedAttachmentTypes.includes(file.type)) {
					approvedFiles.push(file);
				} else {
					alert(t('features:home:error:fileTypeNotAllowed') + '\n > ' + file.name);
				}
			});

			let sizeSum = 0;
			attachments.forEach((file) => {
				sizeSum += file.size;
			});
			approvedFiles.forEach((file) => {
				sizeSum += file.size;
			});
			if (sizeSum > 3145728) {
				// max 3 MB
				alert(t('features:home:error:max3MB'));
			} else {
				setAttachments(attachments.concat(approvedFiles));
			}
		}
		e.currentTarget.value = '';
	};

	const removeAttachment = (filename: string) => {
		setAttachments(attachments.filter((file) => file.name !== filename));
	};

	const handleCaptchaError = () => {
		setCaptchaStatus(CaptchaStatus.ERROR);
	};

	const handleCaptchaExpired = () => {
		setCaptchaStatus(CaptchaStatus.EXPIRED);
	};

	const handleCaptchaSolved = (token: string) => {
		setCaptchaStatus(CaptchaStatus.SOLVED);
		setCaptchaToken(token);
	};

	const submitHandler = (submitValues: ViolationReportForm) => {
		setShowSpinner(true);

		// Verify captcha before sending request
		if (captchaStatus !== CaptchaStatus.SOLVED || !captchaToken) {
			setShowSpinner(false);
			return;
		}

		submitValues.captchaToken = captchaToken;
		if (attachments) {
			submitValues.attachments = attachments;
		}

		const data = formUtils.toFormData(submitValues, locale);

		setRequestFailed(false);
		setSuccessfullySent(false);

		fetch(apiUrl, {
			method: 'POST',
			mode: 'cors',
			body: data
		})
			.then((response) => {
				if (response.ok) {
					setSuccessfullySent(true);
				} else {
					setRequestFailed(true);
				}
			})
			.catch(() => {
				setRequestFailed(true);
			})
			.finally(() => {
				setShowSpinner(false);
			});
	};

	const closeTab = () => {
		window.location.href = apiConfig.URL_COMPANY;
	};

	return (
		<>
			<Helmet>
				<title>{t('document:title')}</title>
				<meta name="description" content={t('document:description')} />
			</Helmet>
			<div className={classes.headerBox}>
				<div className={classes.headerLeftBox}>
					<img src={logo} alt="Breuninger logo" className={classes.logo} />
				</div>
				<div className={classes.headerRightBox}>
					<LanguagePicker selectedLocale={locale} onLocaleChange={onLocaleChange} />
				</div>
			</div>

			<div className={classes.contentBox}>
				<div className={classes.contentTitle}>{t('features:home:title')}</div>

				{!successfullySent && (
					<>
						<ExpandBox
							shownChildren={
								<p id="section1">
									<span>{t('features:home:requestNote:section1:text1')}</span>
									<span>
										<a
											href={
												locale === 'DE'
													? apiConfig.URL_CODE_CONDUCT.DE
													: apiConfig.URL_CODE_CONDUCT.EN
											}
											className={classes.noteLink}
											target="_blank"
											rel="noreferrer"
										>
											{t('features:home:requestNote:section1:text2')}
										</a>
									</span>
									<span>{t('features:home:requestNote:section1:text3')}</span>
									<span>
										<a
											href={
												locale === 'DE'
													? apiConfig.URL_POLICY_HUMAN_RIGHTS.DE
													: apiConfig.URL_POLICY_HUMAN_RIGHTS.EN
											}
											className={classes.noteLink}
											target="_blank"
											rel="noreferrer"
										>
											{t('features:home:requestNote:section1:text4')}
										</a>
									</span>
									<span>{t('features:home:requestNote:section1:text5')}</span>
								</p>
							}
							expandedChildren={
								<>
									<p id="section2">
										<span>{t('features:home:requestNote:section2:text1')}</span>
										<ul>
											<li id="point1">
												<span>
													{t(
														'features:home:requestNote:section2:point1:text1'
													)}
												</span>
												<span>
													<a
														href={
															locale === 'DE'
																? apiConfig.URL_WHISTLEBLOWER_FORM
																: `${
																		apiConfig.URL_WHISTLEBLOWER_FORM
																	}${locale.toLowerCase()}`
														}
														className={classes.noteLink}
														target="_blank"
														rel="noreferrer"
													>
														{t(
															'features:home:requestNote:section2:point1:text2'
														)}
													</a>
												</span>
												<span>
													{t(
														'features:home:requestNote:section2:point1:text3'
													)}
												</span>
												<span className={classes.fontBold}>
													{t(
														'features:home:requestNote:section2:point1:text4'
													)}
												</span>
												<span>
													{t(
														'features:home:requestNote:section2:point1:text5'
													)}
												</span>
												<span className={classes.fontBold}>
													{t(
														'features:home:requestNote:section2:point1:text6'
													)}
												</span>
												<span>
													{t(
														'features:home:requestNote:section2:point1:text7'
													)}
												</span>
											</li>

											<li id="point2">
												<span>
													{t(
														'features:home:requestNote:section2:point2:text1'
													)}
												</span>
												<span>
													<a
														href={`mailto:${apiConfig.MAIL_COMPLIANCE}`}
														className={classes.noteLink}
													>
														{t(
															'features:home:requestNote:section2:point2:text2'
														)}
													</a>
												</span>
											</li>

											{locale !== 'CN' && (
												<li id="point3">
													<div className={classes.point3}>
														<div className={classes.byPost}>
															{t(
																'features:home:requestNote:section2:point3:text1'
															)}
														</div>

														<div className={classes.postInfo}>
															<span>
																{t(
																	'features:home:requestNote:section2:point3:text2'
																)}
															</span>
															<span>
																{t(
																	'features:home:requestNote:section2:point3:text3'
																)}
															</span>
															<span>
																{t(
																	'features:home:requestNote:section2:point3:text4'
																)}
															</span>
															<span>
																{t(
																	'features:home:requestNote:section2:point3:text5'
																)}
															</span>
															<span>
																{t(
																	'features:home:requestNote:section2:point3:text6'
																)}
															</span>
															<span>
																{t(
																	'features:home:requestNote:section2:point3:text7'
																)}
															</span>
														</div>
													</div>
												</li>
											)}

											<li id="point4">
												<div className={classes.point4}>
													<span className={classes.byPhone}>
														{t(
															'features:home:requestNote:section2:point4:text1'
														)}
													</span>
													<div className={classes.phoneInfo}>
														<span>
															{t(
																'features:home:requestNote:section2:point4:text2'
															)}
														</span>
														<span>
															{t(
																'features:home:requestNote:section2:point4:text3'
															)}
														</span>
														<span>
															{t(
																'features:home:requestNote:section2:point4:text4'
															)}
														</span>
													</div>
												</div>
											</li>
										</ul>
									</p>
									<p id="section3">
										{t('features:home:requestNote:section3:text1')}
									</p>
									<p id="section4">
										{t('features:home:requestNote:section4:text1')}
									</p>
									<p id="section5">
										<span>{t('features:home:requestNote:section5:text1')}</span>
										<span className={classes.fontBold}>
											{t('features:home:requestNote:section5:text2')}
										</span>
										<span>{t('features:home:requestNote:section5:text3')}</span>
										<span>
											<a
												href={
													apiConfig.URL_FEDERAL_OFFICE_OF_JUSTICE_REPORTING_OFFICE
												}
												className={classes.noteLink}
												target="_blank"
												rel="noreferrer"
											>
												{t('features:home:requestNote:section5:text4')}
											</a>
										</span>
										<span>{t('features:home:requestNote:section5:text5')}</span>
										<span>
											<a
												href={apiConfig.URL_BAFIN_REPORTING_OFFICE}
												className={classes.noteLink}
												target="_blank"
												rel="noreferrer"
											>
												{t('features:home:requestNote:section5:text6')}
											</a>
										</span>
										<span>{t('features:home:requestNote:section5:text7')}</span>
										<span>
											<a
												href={
													apiConfig.URL_FEDERAL_CARTEL_OFFICE_REPORTING_OFFICE
												}
												className={classes.noteLink}
												target="_blank"
												rel="noreferrer"
											>
												{t('features:home:requestNote:section5:text8')}
											</a>
										</span>
										<span>{t('features:home:requestNote:section5:text9')}</span>
										<span>
											<a
												href={apiConfig.URL_DIGITAL_SERVICES_COORDINATOR}
												className={classes.noteLink}
												target="_blank"
												rel="noreferrer"
											>
												{t('features:home:requestNote:section5:text10')}
											</a>
										</span>
										<span>
											{t('features:home:requestNote:section5:text11')}
										</span>
										<span className={classes.fontBold}>
											{t('features:home:requestNote:section5:text12')}
										</span>
										<span>
											{t('features:home:requestNote:section5:text13')}
										</span>
									</p>
								</>
							}
						/>

						<form
							method="POST"
							encType="multipart/form-data"
							onSubmit={(...args) => void handleSubmit(submitHandler)(...args)}
						>
							<div className={classes.fieldBox}>
								<div className={`${classes.fieldLabel} ${classes.required}`}>
									{t('features:home:violationCategory:title')}
								</div>
								{Object.values(ViolationCategory).map((category) => (
									<div
										key={category}
										className={`${classes.radioOption} ${classes.violationCategoryOption}`}
									>
										<input
											type="radio"
											id={category}
											value={category}
											{...register('violationCategory', {
												required: 'features:home:error:fieldRequired'
											})}
										/>
										<label htmlFor={category}>
											{t(`features:home:violationCategory:items:${category}`)}
										</label>
										<div className={classes.violationCategoryInfo}>
											<Tooltip
												title={t(
													`features:home:violationCategory:itemDescriptions:${category}`
												)}
												color={'#999999'}
												placement={width > 500 ? 'right' : 'left'}
											>
												<div
													className={classes.violationCategoryInfoIconBox}
												>
													<Info
														className={
															classes.violationCategoryInfoIcon
														}
													/>
												</div>
											</Tooltip>
										</div>
									</div>
								))}
								{errors.violationCategory && (
									<div
										className={`${classes.validationError} ${classes.validationErrorMarginLg}`}
									>
										{formUtils.getErrorMessage('violationCategory', errors)}
									</div>
								)}

								<div className={classes.violationCategoryNote}>
									<span>{t('features:home:violationCategory:note:text1')}</span>
									<span>
										<a
											href={apiConfig.URL_CUSTOMER_SERVICE[locale]}
											className={classes.noteLink}
											target="_blank"
											rel="noreferrer"
										>
											{t('features:home:violationCategory:note:text2')}
										</a>
									</span>
									<span>{t('features:home:violationCategory:note:text3')}</span>
								</div>
							</div>

							<div className={classes.fieldBox}>
								<div className={`${classes.fieldLabel} ${classes.required}`}>
									{t('features:home:reportType:title')}
								</div>
								{Object.values(ReportType).map((reportType) => (
									<div key={reportType} className={classes.radioOption}>
										<input
											type="radio"
											id={reportType}
											value={reportType}
											{...register('reportType')}
										/>
										<label htmlFor={reportType}>
											{t(`features:home:reportType:items:${reportType}`)}
										</label>
									</div>
								))}
							</div>

							<div className={classes.fieldBox}>
								<div className={`${classes.fieldLabel} ${classes.required}`}>
									{t('features:home:anonymousType:title')}
								</div>
								{Object.values(AnonymousType).map((anonymousType) => (
									<div key={anonymousType} className={classes.radioOption}>
										<input
											type="radio"
											id={anonymousType}
											value={anonymousType}
											{...register('anonymousType')}
										/>
										<label htmlFor={anonymousType}>
											{t(
												`features:home:anonymousType:items:${anonymousType}`
											)}
										</label>
									</div>
								))}
							</div>

							{watch('anonymousType') === AnonymousType.NOT_ANONYMOUS && (
								<div className={classes.personalInfo}>
									<div className={classes.fieldRow}>
										<div
											className={`${classes.fieldBox} ${classes.fieldBoxContained}`}
										>
											<label
												htmlFor="firstName"
												className={`${classes.fieldLabel} ${classes.required}`}
											>
												{t('features:home:firstName')}
											</label>
											<input
												id="firstName"
												required
												{...register('firstName', {
													setValueAs: (v: string) => v.trim(),
													required: 'features:home:error:fieldRequired'
												})}
											/>
											{errors.firstName && (
												<div className={classes.validationError}>
													{formUtils.getErrorMessage('firstName', errors)}
												</div>
											)}
										</div>

										<div
											className={`${classes.fieldBox} ${classes.fieldBoxContained}`}
										>
											<label
												htmlFor="lastName"
												className={`${classes.fieldLabel} ${classes.required}`}
											>
												{t('features:home:lastName')}
											</label>
											<input
												id="lastName"
												required
												{...register('lastName', {
													setValueAs: (v: string) => v.trim(),
													required: 'features:home:error:fieldRequired'
												})}
											/>
											{errors.lastName && (
												<div className={classes.validationError}>
													{formUtils.getErrorMessage('lastName', errors)}
												</div>
											)}
										</div>
									</div>
									<div className={classes.fieldRow}>
										<div
											className={`${classes.fieldBox} ${classes.fieldBoxContained}`}
										>
											<label
												htmlFor="email"
												className={`${classes.fieldLabel}`}
											>
												{t('features:home:email')}
											</label>
											<input
												id="email"
												type="email"
												{...register('email', {
													setValueAs: (v: string) => v.trim()
												})}
											/>
											<div className={classes.fieldNote}>
												{t('features:home:optionalField')}
											</div>
											{errors.email && (
												<div className={classes.validationError}>
													{formUtils.getErrorMessage('email', errors)}
												</div>
											)}
										</div>

										<div
											className={`${classes.fieldBox} ${classes.fieldBoxContained}`}
										>
											<label
												htmlFor="phone"
												className={`${classes.fieldLabel}`}
											>
												{t('features:home:phone')}
											</label>
											<input
												id="phone"
												{...register('phone', {
													setValueAs: (v: string) => v.trim()
												})}
											/>
											<div className={classes.fieldNote}>
												{t('features:home:optionalField')}
											</div>
											{errors.phone && (
												<div className={classes.validationError}>
													{formUtils.getErrorMessage('phone', errors)}
												</div>
											)}
										</div>
									</div>
								</div>
							)}

							<div className={classes.fieldBox}>
								<div className={classes.fieldLabelBox}>
									<label
										htmlFor="description"
										className={`${classes.fieldLabel} ${classes.required}`}
									>
										{t('features:home:description')}
									</label>
									<span className={classes.fieldLabelNote}>
										{t('features:home:descriptionNote')}
									</span>
								</div>
								<textarea
									id="description"
									required
									className={classes.descriptionTextarea}
									{...register('description', {
										setValueAs: (v: string) => v.trim(),
										required: 'features:home:error:fieldRequired'
									})}
								/>
								{errors.description && (
									<div className={classes.validationError}>
										{formUtils.getErrorMessage('description', errors)}
									</div>
								)}
							</div>

							<div className={classes.fieldBox}>
								<div className={classes.fieldLabel}>
									{t('features:home:attachment:label')}
								</div>
								<div className={classes.labelAttachmentSub}>
									{t('features:home:attachment:labelSub')}
								</div>
								<div className={classes.uploadDropzone}>
									<input
										type="file"
										name="files"
										multiple
										accept={allowedAttachmentTypes.join()}
										onChange={onAttachmentChange}
									/>
									<span className={classes.spanMain}>
										<span className={classes.fakeHref}>
											{t('features:home:attachment:loadFile')}
										</span>{' '}
										{t('features:home:attachment:dropFile')}
									</span>
								</div>

								<ul className={classes.uploadPool}>
									{attachments.map((file, index) => (
										<li className={classes.uploadItem} key={index}>
											<span className={classes.itemName}>{file.name}</span>
											<span
												className={classes.itemRemove}
												onClick={() => removeAttachment(file.name)}
											>
												x
											</span>
										</li>
									))}
								</ul>
							</div>

							{requestFailed && (
								<div className={classes.errorMessage}>
									{t('features:home:error:requestFailed')}
								</div>
							)}

							{captchaStatus === CaptchaStatus.ERROR && (
								<div className={classes.errorMessage}>
									{t('features:home:error:captchaFailed')}
								</div>
							)}

							<div className={classes.submitBox}>
								<button
									type="submit"
									disabled={captchaStatus !== CaptchaStatus.SOLVED}
								>
									{t('features:home:submit')}
								</button>
							</div>

							{captchaSiteKey && (
								<div className={classes.captchaBox}>
									<Turnstile
										siteKey={captchaSiteKey}
										onSuccess={(token) => handleCaptchaSolved(token)}
										onError={() => handleCaptchaError()}
										onExpire={() => handleCaptchaExpired()}
										options={{
											theme: 'light',
											language: captchaLang
										}}
										className={classes.captcha}
									/>
								</div>
							)}
						</form>
					</>
				)}

				{successfullySent && (
					<div className={classes.successBox}>
						<div className={classes.successMsgBox}>
							<img
								src={success}
								alt="Erfolreich gesendet"
								className={classes.successIcon}
							/>

							<div className={classes.successMsg}>
								{t('features:home:sent:success')}
							</div>
						</div>
						<button type="button" onClick={closeTab}>
							{t('features:home:sent:close')}
						</button>
					</div>
				)}
			</div>

			<div className={classes.footerBox}>
				<a
					href={apiConfig.URL_IMPRINT[locale]}
					className={classes.footerLink}
					target="_blank"
					rel="noreferrer"
				>
					{t('features:footer:imprint')}
				</a>
				<a
					href={apiConfig.URL_PRIVACY_POLICY}
					className={classes.footerLink}
					target="_blank"
					rel="noreferrer"
				>
					{t('features:footer:privacyPolicy')}
				</a>
				<a
					href={
						locale === 'DE'
							? apiConfig.URL_CODE_CONDUCT.DE
							: apiConfig.URL_CODE_CONDUCT.EN
					}
					className={classes.footerLink}
					target="_blank"
					rel="noreferrer"
				>
					{t('features:footer:codeConduct')}
				</a>
				<a
					href={
						locale === 'DE'
							? apiConfig.URL_POLICY_HUMAN_RIGHTS.DE
							: apiConfig.URL_POLICY_HUMAN_RIGHTS.EN
					}
					className={classes.footerLink}
					target="_blank"
					rel="noreferrer"
				>
					{t('features:footer:policyStatement')}
				</a>
				<a
					href={apiConfig.URL_PROCESS_DESCRIPTION}
					className={classes.footerLink}
					target="_blank"
					rel="noreferrer"
				>
					{t('features:footer:processDescription')}
				</a>
			</div>

			{showSpinner && (
				<div className={classes.spinnerBox}>
					<img src={spinner} className={classes.spinnerImg} alt="Loading..." />
				</div>
			)}
		</>
	);
};
