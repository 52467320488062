import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { i18n } from 'i18n';
import { i18nConfig } from 'i18n/i18n.config';
import { Homepage } from 'features/homepage/Homepage';
import 'app/App.less';

function App() {
	const [loading, setLoading] = useState(true);
	const [locale, setLocale] = useState<keyof typeof i18nConfig.locales>('DE');
	const [langParamExists, setLangParamExists] = useState<boolean>(false);

	const navigate = useNavigate();
	const { pathname } = useLocation();

	const initI18 = async (
		locale?: keyof typeof i18nConfig.locales,
		loadingIndicator?: boolean
	) => {
		if (loadingIndicator) setLoading(true);
		await i18n.initialize(locale);
		if (loadingIndicator) setLoading(false);
	};

	useEffect(() => {
		let loc = locale;
		const langParam = pathname !== '/' ? pathname.substring(1).toUpperCase() : null;
		if (langParam && Object.keys(i18nConfig.locales).includes(langParam)) {
			loc = langParam as keyof typeof i18nConfig.locales;
			setLocale(loc);
			setLangParamExists(true);
		}

		void (async function anyNameFunction() {
			setLoading(true);
			await initI18(loc);
			setLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onLocaleChange = (newLocale: string) => {
		const l = newLocale as keyof typeof i18nConfig.locales;
		setLocale(l);
		navigate(`/${newLocale.toLowerCase()}`, { replace: true });
		setLangParamExists(true);
		void initI18(l);
	};

	if (loading) return <div></div>;

	return (
		<div className="app-box">
			<Helmet>
				{langParamExists ? (
					<link
						rel="canonical"
						href={`${document.location.origin}/${locale.toLowerCase()}`}
					/>
				) : (
					<link rel="canonical" href={`${document.location.origin}/de`} />
				)}
				{Object.entries(i18nConfig.locales).map(([key, val]) => (
					<link
						key={key}
						rel="alternate"
						href={`${document.location.origin}/${key.toLowerCase()}`}
						hrefLang={val.hrefLang}
					/>
				))}
			</Helmet>
			<Homepage locale={locale} onLocaleChange={onLocaleChange} />
		</div>
	);
}

export default App;
