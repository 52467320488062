import i18next from 'i18next';
import { DeepMap, FieldError, get } from 'react-hook-form';
import { i18n } from 'i18n';
import { AnonymousType, ViolationReportForm } from 'model/formModels';

const getErrorMessage = (
	name: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errors: DeepMap<Record<string, any>, FieldError>
): undefined | string => {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
	const message: string = get(errors, name)?.message;
	if (!message) return; // No error

	// Check if dictionary entry exists, else return 'key'
	return i18next.exists(message) ? i18next.t(message) : message;
};

const toFormData = (submitValues: ViolationReportForm, locale: string) => {
	const data = new FormData();
	data.append(
		'violationCategory',
		i18n.t('features:home:violationCategory:items:' + submitValues.violationCategory)
	);
	data.append('reportType', i18n.t('features:home:reportType:items:' + submitValues.reportType));
	data.append(
		'anonymousType',
		i18n.t('features:home:anonymousType:items:' + submitValues.anonymousType)
	);
	data.append(
		'anonymous',
		submitValues.anonymousType === AnonymousType.ANONYMOUS ? 'true' : 'false'
	);

	if (submitValues.anonymousType === AnonymousType.NOT_ANONYMOUS) {
		data.append('firstName', submitValues.firstName);
		data.append('lastName', submitValues.lastName);

		if (submitValues.email) {
			data.append('email', submitValues.email);
		}
		if (submitValues.phone) {
			data.append('phone', submitValues.phone);
		}
	}

	if (submitValues.description) {
		data.append('description', submitValues.description);
	}
	if (submitValues.attachments) {
		submitValues.attachments.forEach((file) => data.append(file.name, file));
	}
	data.append('language', locale);
	data.append('timestamp', new Date().toISOString());
	data.append('captchaToken', submitValues.captchaToken);

	return data;
};

export const formUtils = {
	getErrorMessage,
	toFormData
};
