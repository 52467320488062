const getCaptchaLang = (locale: string) => {
	switch (locale.toUpperCase()) {
		case 'DE':
			return 'de';
		case 'EN':
			return 'en';
		case 'CN':
			return 'zh-CN';
		case 'CZ':
			return 'en';
		case 'FR':
			return 'fr';
		case 'GR':
			return 'en';
		case 'IT':
			return 'it';
		case 'PL':
			return 'pl';
		case 'TR':
			return 'tr';
		default:
			return 'en';
	}
};

export const cvrUtils = {
	getCaptchaLang
};
